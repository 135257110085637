export const API_URL =
  "https://cors-anywhere.herokuapp.com/https://www.delfi.lv/misc/task_2020/";

// List of Channels generated in modal.js
export const CHANNELS = {
  0: "Visi",
  1: "Delfi",
  7: "Delfi Pluss",
  40: "MVP",
  999: "Cits",
};
